<template>
  <div>
    <Navbar page="Canal de Denúncias" link="/incidentes" nameLink="Gestão de Incidentes" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="$modal.show('novaDenuncia')"
            type="button"
            class="transition duration-200 bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
          >
            <span class="inline-block">Registrar denúncia manualmente</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="excluirSelecionadas" v-if="selecionados.length" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Arquivar selecionadas</span>
          </button>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-4 mb-1 mt-1">
          <input
            v-model="busca"
            type="text"
            name="busca"
            id="busca"
            placeholder="Filtrando Protocolo"
            class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"
          />
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <label for="naoContemAvaliacao" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="arquivados" class="rounded-sm" id="naoContemAvaliacao">
            <span class="ml-2">
                Visualizar desativadas
            </span>
          </label>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button
            @click="start"
            class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            Buscar
          </button>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Protocolo
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Tipo da denúncia
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Canal
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Data de registro
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Gravidade atribuída
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Data Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                    >
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" :class="item.finalizado ? 'bg-green-100': '' ">
                    <td class="px-3 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.protocolo ? item.protocolo : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.tipoDenuncia ? item.tipoDenuncia : '' }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm text-gray-500">
                          {{ item.canal ? item.canal : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.dataRegistro" class="text-sm text-gray-500">
                          {{ item.dataRegistro | moment("DD/MM/YYYY HH:mm") }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm_2 font-medium text-gray-500" :style="{ color: item.gravidade ? statusGrauDeRisco(item.gravidade) : '' }">
                            {{item.gravidade ? item.gravidade : ''}}
                          </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div
                          v-if="item.status === 'Pendente'"
                          class="text-sm font-semibold text-pendente"
                        >
                          {{ item.status ? item.status : "" }}
                        </div>
                        <div v-else-if="item.status === 'Em análise'" class="text-sm font-semibold text-andamento">
                          {{ item.status ? item.status : "" }}
                        </div>
                        <div v-else class="text-sm font-semibold text-concluido">
                          {{ item.status ? item.status : "" }}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.dataAtualizacao" class="text-sm text-gray-500">
                          {{ item.dataAtualizacao | moment("DD/MM/YYYY HH:mm")}}
                        </div>
                      </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <router-link
                        :to="`/${route}/analisar/${item._id}`"
                        @click="remove(item._id)"
                        type="button"
                        class="text-white hover:bg-blue-500 bg-blue-700 rounded py-1 px-4 mx-1"
                      >
                        Analisar
                      </router-link>

                      <button
                        v-if="!arquivados"
                        @click="remove(item._id)"
                        type="button"
                        class="text-white hover:bg-red-500 bg-red-700 rounded py-1 px-4 mx-1"
                      >
                        Arquivar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              v-model="page"
              :per-page="perPage"
              :records="total"
              @paginate="setPage"
              class="pagination"
            />
          </div>
        </div>
      </div>


      <modal name="novaDenuncia" :width="850" :adaptive="true" :height="'auto'" :clickToClose="false">
        <div class="px-3 py-4">
          <h2 class="text-2xl font-bold mb-3">Registro manual denúncia</h2>
          <div class="grid grid-cols-12 gap-x-2 gap-y-3">
            <div class="col-span-12 md:col-span-4">
              <label for="tipoDenuncia" class="block text-sm font-medium">Tipo da denúncia</label>
              <select required v-model="form.tipoDenuncia" name="tipoDenuncia" id="tipoDenuncia" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="Anônima">Anônima</option>
                <option value="Identificada">Identificada</option>
              </select>
            </div>
            <div v-if="form.tipoDenuncia === 'Identificada'" class="col-span-12 md:col-span-4">
              <label for="nome" class="block text-sm font-medium">Nome</label>
              <input type="text" v-model="form.nome" name="nome" id="nome" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>
            <div v-if="form.tipoDenuncia === 'Identificada'" class="col-span-12 md:col-span-4">
              <label for="email" class="block text-sm font-medium">Email</label>
              <input type="text" v-model="form.email" name="email" id="email" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="canal" class="block text-sm font-medium">Canal</label>
              <select required v-model="form.canal" name="canal" id="canal" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="Site">Site</option>
                <option value="Email">Email</option>
                <option value="Telefone">Telefone</option>
                <option value="Pessoalmente">Pessoalmente</option>
                <option value="Outro">Outro</option>
              </select>
            </div>
            <div class="col-span-12 md:col-span-4">
              <label for="gravidade" class="block text-sm font-medium">Gravidade atribuída</label>
              <select required v-model="form.gravidade" name="gravidade" id="gravidade" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option value="Muito baixa">Muito baixa</option>
                <option value="Baixa">Baixa</option>
                <option value="Média">Média</option>
                <option value="Alto">Alto</option>
                <option value="Muito Alto">Muito Alto</option>
              </select>
            </div>
            <div class="col-span-12">
              <label for="conteudo" class="block text-sm font-medium">Conteúdo da denúncia</label>
              <textarea v-model="form.conteudo" type="text" name="conteudo" id="conteudo" rows="2" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
          </div>
          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('novaDenuncia')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class="text-right">
                <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                    Salvar
                </button>
            </div>
          </div>
        </div>
      </modal>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route: "denuncias",
      form: {
        tipoDenuncia: '',
        nome: '',
        email: '',
        canal: '',
        gravidade: '',
        conteudo: '',
        procedimentos: '',
      },
      list: [],
      selecionados: [],
      arquivados: false,
      page: 1,
      perPage: 10,
      busca: "",
      total: 0,
      dropzoneOptions: this.$http.getDropzoneConfig(
        `v1/requisicao/importar-csv`,
        'post',
      {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: 'Clique ou arraste seu arquivo aqui',
        dictRemoveFile: 'Remover'
      }),
    };
  },
  methods: {
    async start() {
      const listReq = await this.$http.post(`/v1/${this.route}/list`, {
        busca: this.busca,
        limit: this.perPage,
        arquivados: this.arquivados
      });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Arquivado com sucesso!");
      this.start();
    },

    async save() {
      const req = await this.$http.post(`/v1/${this.route}`, this.form);
      if (req.data.success) {
          this.$vToastify.success("Gerado com sucðesso!");
          this.$modal.hide('novaDenuncia');
          this.start();
          this.form = {
            tipoDenuncia: '', nome: '', email: '', canal: '', gravidade: '', conteudo: '', procedimentos: ''
          }
      } else {
          this.$vToastify.error(req.data.err);
      }
    },

    async selecionarTodos(){
      if (this.selecionados.length) {
        this.selecionados = [];
      } else {
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { all: true, busca: this.busca });
        this.selecionados = listReq.data.data;
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { limit, skip, arquivados: this.arquivados });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    statusGrauDeRisco(status){
      if(status === 'muitobaixa') return '#3a9669';
      if(status === 'baixo') return '#1b4e3b';
      if(status === 'medio') return '#f29e0c';
      if(status === 'alto') return '#dc2626';
      if(status === 'muitoalto') return '#111827';
    },

  },
  async beforeMount() {
    this.start();

    this.$watch('arquivados', async (arquivados) => {
      this.skip = parseInt(0);
      this.arquivados = arquivados;
      await this.start();
    });
  },
};
</script>
